import React, { useState } from "react"
import styled from "@emotion/styled"
import Gallery from "./gallery"

const ButtonWrapper = styled.div`
  height: 100%;
  margin: 0.5rem;
  ${props => props.theme.tablet} {
    margin: 0.5rem;
  }
`

const ButtonOutsideWrapper = styled.div`
  width: 20%;
  padding-bottom: 2rem;

  ${props => props.theme.tablet} {
    width: 50%;
  }
`
const Image = styled.img`
  max-width: 100%;
  min-height: 200px;
`
const Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: #ffffff;
  border: 1px solid ${props => props.theme.colors.line};
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  }
`

const InfroWrapper = styled.div`
  padding: 1rem;
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
  ${props => props.theme.tablet} {
    font-size: 0.7rem;
    padding: 1rem 0.5rem;
  }
`

const DateTime = styled.span`
  font-weight: 600;
`

const PhotosAmount = styled.span`
  color: ${props => props.theme.colors.secondary};
`

const GalleryButton = props => {
  const [open, setGallery] = useState(false)
  const { monthAndYear, ...photos } = props
  const {
    photoAndAnnotation1: { photo },
  } = props

  function handleClick() {
    setGallery(state => !state)
  }

  const arrayOfGalleries = Object.values(photos)
  const filtredArrayPhotos = arrayOfGalleries.filter(gallery => gallery.photo)
  let [day, month, year] = monthAndYear.split("/")
  const date = new Date(`${month}/${day}/${year}`).toLocaleDateString("ru-Ru", {
    year: "numeric",
    month: "long",
  })

  return (
    <ButtonOutsideWrapper>
      <ButtonWrapper>
        <Button onClick={handleClick}>
          <div>
            <Image src={photo.sourceUrl} alt="" />
          </div>
          <InfroWrapper>
            <DateTime>{date}</DateTime>
            <PhotosAmount>{filtredArrayPhotos.length} фото</PhotosAmount>
          </InfroWrapper>
        </Button>
      </ButtonWrapper>
      {open && (
        <Gallery
          date={date}
          onCloseButton={handleClick}
          photos={filtredArrayPhotos}
        />
      )}
    </ButtonOutsideWrapper>
  )
}

export default GalleryButton
