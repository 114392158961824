import React, { useRef, useEffect, useCallback } from "react"
import styled from "@emotion/styled"
import Row from "../layout/row"
import CloseButton from "../common/closebutton"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, Keyboard } from "swiper"

SwiperCore.use([Navigation, Pagination, Keyboard])

const RowSt = styled(Row)`
  position: relative;
  ${props => props.theme.tablet} {
    max-width: 100vw;
    width: 1330px;
  }
`

const GalleryWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  color: ${props => props.theme.colors.footerFont};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .swiper-pagination {
    bottom: 3.2rem;
  }
  .swiper-pagination-bullet {
    background: white;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: white;
    ${props => props.theme.tablet} {
      top: 40%;
    }
  }
`

const SwipperWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  .swiper-pagination {
    bottom: 4rem;
  }
  .swiper-pagination-bullet {
    width: 0.5rem;
    height: 0.5rem;
  }
`

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Image = styled.img`
  max-width: 100%;
  display: block;
  margin: 0 auto;
  max-height: 70vh;
  flex-shrink: 2;
`

const DateOfPhotos = styled.span`
  font-size: 2rem;
  ${props => props.theme.tablet} {
    font-size: 1rem;
    padding: 0 0 0.5rem 0.5rem;
  }
`

const ImageWrapper = styled.div`
  background: black;
  width: 100%;
`
const Annotation = styled.div`
  font-size: 1.3rem;
  flex: 1;
  padding-top: 1rem;
  ${props => props.theme.tablet} {
    font-size: 0.8rem;
  }
`

const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Gallery = props => {
  const wrapperRef = useRef(null)
  const { onCloseButton } = props

  const slides = props.photos.map((item, idx) => (
    <SwiperSlide key={`${item.photo.sourceUrl}_${idx}`}>
      <Slide>
        <ImageWrapper>
          <Image src={item.photo.sourceUrl} alt={item.annotation} />
        </ImageWrapper>
        <Annotation>{item.annotation}</Annotation>
      </Slide>
    </SwiperSlide>
  ))

  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) {
        onCloseButton()
      }
    },
    []
  )

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onCloseButton()
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      document.addEventListener("keydown", escFunction, false)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
        document.removeEventListener("keydown", escFunction, false)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  return (
    <GalleryWrapper>
      <RowSt>
        <div ref={wrapperRef}>
          <DateOfPhotos>{props.date}</DateOfPhotos>
          <SwipperWrapper>
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              navigation
              pagination
              keyboard
            >
              {slides}
            </Swiper>
            <CloseButton onClick={() => props.onCloseButton()}>
              &times;
            </CloseButton>
          </SwipperWrapper>
        </div>
        <CloseButtonWrapper></CloseButtonWrapper>
      </RowSt>
    </GalleryWrapper>
  )
}

export default Gallery
