import React from "react"
import { graphql, navigate } from "gatsby"
import SiteLayout from "../components/layout/index"
import Row from "../components/layout/row"
import styled from "@emotion/styled"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import GalleryButton from "../components/objects/galleryButton"
import Order from "../components/common/order"
import { Helmet } from "react-helmet"

// Import Swiper styles
import "swiper/swiper-bundle.css"

const STabs = styled(Tabs)`
  width: 100%;
`

const GallariesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
`

const ItemWrapper = styled("div")`
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-bottom: 1px solid ${props => props.theme.colors.line};
  img {
    width: 40px;
    margin-right: 1rem;
  }
  ${props => props.theme.tablet} {
    padding: 1rem 0;
  }
`

const STabList = styled(TabList)`
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
STabList.tabsRole = "TabList"

const STab = styled(Tab)`
  display: block;
  white-space: nowrap;
  margin-right: 1rem;
  padding: 1rem;
  user-select: none;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.activeLink};
  }
  &.is-selected {
    border-bottom: 3px solid ${props => props.theme.colors.activeLink};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
  }
  ${props => props.theme.tablet} {
    padding: 1rem 0;
  }
`
STab.tabsRole = "Tab"

const STabPanel = styled(TabPanel)`
  display: none;
  min-height: 40vh;
  padding: 1rem 0;
  margin-top: -3px;
  border-top: 3px solid ${props => props.theme.colors.line};
  &.is-selected {
    display: block;
  }
`
STabPanel.tabsRole = "TabPanel"

const ContentWrapper = styled.div`
  display: flex;
  ${props => props.theme.tablet} {
    flex-direction: column-reverse;
  }
`

const Content = styled.div`
  flex: 1;
  overflow: hidden;
  img {
    max-width: 100%;
    display: block;
  }
  padding-right: 6rem;
  margin-bottom: 2rem;

  ${props => props.theme.tablet} {
    padding-right: 0;
  }
`
const ObjectHeader = styled.h1`
  margin: 1rem 0;
  ${props => props.theme.tablet} {
    font-size: 1.2rem;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
  &:first-of-type {
    margin: 0 0 1.5rem;
  }
  &:last-of-type {
    margin: 0 0 1.5rem;
  }
  ${props => props.theme.tablet} {
    flex-direction: column;
  }
`

const Owner = styled(InfoWrapper)`
  border-bottom: 1px solid ${props => props.theme.colors.line};
  padding-bottom: 1.5rem;
`

const Info = styled.div`
  font-weight: 600;
`

const InfoType = styled.span`
  font-size: 0.9rem;
  color: ${props => props.theme.colors.secondary};
`

const ImageWrapper = styled.div`
  flex-basis: 40%;
  img {
    display: block;
    max-width: 100%;
    min-height: 300px;
    background-color: rgba(0, 0, 0, 0.03);
  }
  ${props => props.theme.tablet} {
    margin-bottom: 1rem;
  }
`
const BackwardButton = styled.button`
  border-width: 0px;
  margin-bottom: 0.5rem;
  background-color: transparent;
  font-size: 1.2rem;
  padding: 0;
  line-height: 1;
  font-family: inherit;
  cursor: pointer;
  color: ${props => props.theme.colors.activeLink};
  &:focus {
    outline: none;
  }
`

const Building = props => {
  const goBack = () => {
    navigate(-1)
  }

  const {
    data: {
      wordPress: { objectBuilding },
    },
  } = props

  const { objects } = objectBuilding

  const newObject = Object.keys(objects).reduce((newObj, key) => {
    const value = objects[key]
    if (value !== null) {
      newObj[key] = value
    }
    return newObj
  }, {})

  const {
    adress,
    floors = "-",
    owner = "-",
    amountoflivingrooms = "-",
    amountofnonlivingrooms = "-",
    livingspace = "-",
    nonlivingspace = "-",
    gallery,
    cover,
    documents,
    order,
  } = newObject

  const orderData = [
    {
      q: "Решение о финансировании мероприятий по завершению строительства",
      a: <div dangerouslySetInnerHTML={{ __html: order.step1.information }} />,
      done: order.step1.done,
    },
    {
      q:
        "Оформление прав на земельный участок и объекты незавершенного строительства",
      a: <div dangerouslySetInnerHTML={{ __html: order.step2.information }} />,
      done: order.step2.done,
    },
    {
      q: "Строительные мероприятия",
      a: <div dangerouslySetInnerHTML={{ __html: order.step3.information }} />,
      done: order.step3.done,
    },
    {
      q:
        "Ввод объекта в эксплуатацию, передача квартир участникам строительства",
      a: <div dangerouslySetInnerHTML={{ __html: order.step4.information }} />,
      done: order.step4.done,
    },
  ]

  const filtredDocuments = Object.keys(documents).reduce((newObj, key) => {
    const value = documents[key]
    if (value !== null) {
      newObj[key] = value
    }
    return newObj
  }, {})

  const arrayOfDocuments = Object.values(filtredDocuments)

  const documentEls = arrayOfDocuments.map(document => {
    return (
      <ItemWrapper key={document.title}>
        <img src={document.sourceUrl} alt="" />
        <a href={document.mediaItemUrl} target="_blank" rel="noreferrer">
          {document.title}
        </a>
      </ItemWrapper>
    )
  })

  const galleryWithPhotos = Object.keys(gallery).reduce((newObj, key) => {
    const value = gallery[key]
    if (value.monthAndYear !== null) {
      newObj[key] = value
    }
    return newObj
  }, {})

  const arrayOfGalleries = Object.values(galleryWithPhotos)

  const gallaries = arrayOfGalleries.map((gallery, idx) => (
    <GalleryButton {...gallery} key={`${gallery.adress}_${idx}`} />
  ))

  return (
    <SiteLayout>
      <Helmet>
        <title>{`${objectBuilding.title}, ${owner}`}</title>
        <meta
          name="description"
          content={`Объект ${objectBuilding.title}, ${owner} в работе фонда`}
        />
      </Helmet>
      <Row margin="1rem auto">
        <BackwardButton onClick={goBack}>&larr; Назад</BackwardButton>
        <ObjectHeader>{objectBuilding.title}</ObjectHeader>
        <ContentWrapper>
          <Content>
            <Owner>
              <InfoType>Застройщик</InfoType>
              <Info>{owner}</Info>
            </Owner>
            <InfoWrapper>
              <InfoType>Количество этажей</InfoType>
              <Info>{floors}</Info>
            </InfoWrapper>
            <InfoWrapper>
              <InfoType>Количество жилых помещений</InfoType>
              <Info>{amountoflivingrooms}</Info>
            </InfoWrapper>
            <InfoWrapper>
              <InfoType>Жилая площадь, м²</InfoType>
              <Info>{livingspace}</Info>
            </InfoWrapper>
            <InfoWrapper>
              <InfoType>Количество нежилых помещений</InfoType>
              <Info>{amountofnonlivingrooms}</Info>
            </InfoWrapper>
            <InfoWrapper>
              <InfoType>Нежилая площадь, м²</InfoType>
              <Info>{nonlivingspace}</Info>
            </InfoWrapper>
          </Content>
          <ImageWrapper>
            <img src={cover.sourceUrl} alt={adress} />
          </ImageWrapper>
        </ContentWrapper>
        <div></div>
        <div>
          <STabs
            selectedTabClassName="is-selected"
            selectedTabPanelClassName="is-selected"
          >
            <STabList>
              <STab>Порядок действий</STab>
              <STab>Ход строительства</STab>
              <STab>Документы</STab>
            </STabList>
            <STabPanel>
              <div>
                <Order list={orderData} startingIndex={1} />
              </div>
            </STabPanel>
            <STabPanel>
              <GallariesWrapper>{gallaries}</GallariesWrapper>
            </STabPanel>
            <STabPanel>{documentEls}</STabPanel>
          </STabs>
        </div>
      </Row>
    </SiteLayout>
  )
}

export default Building

export const pageQuery = graphql`
         query GET_OBJECT($id: ID!) {
           wordPress {
             objectBuilding(id: $id) {
               title
               objects {
                 order {
                   step1 {
                     done
                     information
                   }
                   step2 {
                     done
                     information
                   }
                   step3 {
                     done
                     information
                   }
                   step4 {
                     done
                     information
                   }
                 }
                 adress
                 amountoflivingrooms
                 amountofnonlivingrooms
                 date
                 floors
                 livingspace
                 nonlivingspace
                 owner
                 cover {
                   sourceUrl(size: LARGE)
                 }
                 documents {
                   document1 {
                     title(format: RENDERED)
                     mediaItemUrl
                     sourceUrl(size: THUMBNAIL)
                   }
                   document2 {
                     title(format: RENDERED)
                     mediaItemUrl
                     sourceUrl(size: THUMBNAIL)
                   }
                   document3 {
                     title(format: RENDERED)
                     mediaItemUrl
                     sourceUrl(size: THUMBNAIL)
                   }
                   document4 {
                     title(format: RENDERED)
                     mediaItemUrl
                     sourceUrl(size: THUMBNAIL)
                   }
                   document5 {
                     title(format: RENDERED)
                     mediaItemUrl
                     sourceUrl(size: THUMBNAIL)
                   }
                   document6 {
                     title(format: RENDERED)
                     mediaItemUrl
                     sourceUrl(size: THUMBNAIL)
                   }
                   document7 {
                     title(format: RENDERED)
                     mediaItemUrl
                     sourceUrl(size: THUMBNAIL)
                   }
                   document8 {
                     title(format: RENDERED)
                     mediaItemUrl
                     sourceUrl(size: THUMBNAIL)
                   }
                   document9 {
                     title(format: RENDERED)
                     mediaItemUrl
                     sourceUrl(size: THUMBNAIL)
                   }
                   document10 {
                     title(format: RENDERED)
                     mediaItemUrl
                     sourceUrl(size: THUMBNAIL)
                   }
                 }

                 gallery {
                   gallery1 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery2 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery3 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery4 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery5 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery6 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery7 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery8 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery9 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery10 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery11 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery12 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery13 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery14 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery15 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery16 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery17 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery18 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery19 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery20 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery21 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery22 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery23 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                         
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                   gallery24 {
                     photoAndAnnotation1 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation2 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation3 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation4 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation5 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation6 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation7 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation8 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation9 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     photoAndAnnotation10 {
                       annotation
                       photo {
                         sourceUrl(size: LARGE)
                       }
                     }
                     monthAndYear
                   }
                 }
               }
             }
           }
         }
       `
